import { HttpInterceptorFn } from '@angular/common/http';
import { UserStorageService } from '../../login/user-storage.service';
import { inject } from '@angular/core';

export const authHeaderInterceptor: HttpInterceptorFn = (req, next) => {
  const userStorageService = inject(UserStorageService);
  const user = userStorageService.getUser();

  if (user && user.matricule) {
    const authReq = req.clone({
      headers: req.headers.set('Authorization', `Matricule ${user.matricule}`)
    });
    return next(authReq);
  }

  return next(req);
};
