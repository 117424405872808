import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { HeaderComponent } from '../header/header.component';
import { MatDivider } from '@angular/material/divider';
import { MatList, MatListItem } from '@angular/material/list';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-form',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    HeaderComponent,
    MatDivider,
    MatList,
    MatListItem,
    ReactiveFormsModule,
    MatIconModule,
    FormsModule
  ],
  template: `
    <div class="w-full h-full custom-background-gradient">
      <div class="container mx-auto flex flex-col justify-center items-center min-h-screen">
        <div
          class="flex flex-col items-center justify-center w-3/5 min-h-[40vh] rounded-xl bg-white relative login-container mat-elevation-z5">
          <img src="assets/header-logo.png" alt="logo" class="h-[60px] absolute top-8 left-8"/>
          <div class="w-[300px] flex flex-col justify-center items-center pb-5">
            <h1>Connexion</h1>
            <p class="text-grey-700">Entrez votre matricule</p>
            <div class="my-4 w-full">
              <mat-divider/>
            </div>
            <form class="flex flex-col items-center justify-center w-full" (ngSubmit)="login()">
              <mat-form-field appearance="outline" class="w-full">
                <mat-label>Matricule</mat-label>
                <input matInput [formControl]="matricule" placeholder="Entrez votre matricule" required>
              </mat-form-field>
            <button mat-raised-button color="primary" type="submit" class="w-full" (click)="login()">
              <mat-icon>login</mat-icon>
              Connexion
            </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: `
    .custom-background-gradient {
      background-image: linear-gradient(to right top, #1e5193, #0076ab, #00969b, #0faf70, #a1be47);
    }

    .login-container {
      backdrop-filter: blur(3.6px);
      -webkit-backdrop-filter: blur(3.6px);
      border: 1px solid rgba(255, 255, 255, 0.63);
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginFormComponent {
  private fb = inject(FormBuilder);
  private authService = inject(AuthService);
  private router = inject(Router);

  matricule = this.fb.control('', [Validators.required, Validators.pattern(/^\S*$/)]);

  login() {
    if (this.matricule.invalid) return;
    this.authService.login(this.matricule.value!).subscribe(() => {
      this.router.navigate(['/home']);
    });
  }
}
