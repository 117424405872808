import { HttpInterceptorFn } from '@angular/common/http';

/**
 * Le serveur sur lequel on envoie la requête attend un header `Content-Type` avec la valeur `application/json`.
 * Sinon le résultat de la requête est mise en cache. Même sur les GET. Ops est prévenu.
 * @param req
 * @param next
 */
export const contentTypeInterceptor: HttpInterceptorFn = (req, next) => {
  if(!req.headers.has('Content-type') && !(req.body instanceof FormData)) {
    const updatedReq = req.clone({
      headers: req.headers.set('Content-type', `application/json`)
    });
    return next(updatedReq);
  }
  return next(req);
};
